import React, { useState, useEffect } from 'react';
import { useStripe, PaymentRequestButtonElement, Elements } from '@stripe/react-stripe-js';
import { loadStripe, PaymentRequest } from '@stripe/stripe-js';
import { useAppSelector } from '@/lib/hooks';

const PaymentComponent: React.FC = () => {
  const stripe = useStripe();
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | null>(null);
  const [applePaySupported, setApplePaySupported] = useState(false);
  const state = useAppSelector((state) => state.paymentForm);
  const { clientSecret, paymentId } = useAppSelector(
    (state) => state.paymentMethod
  );
  useEffect(() => {
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          label: 'Account Purchase',
          amount: 5000, // $50.00
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      pr.canMakePayment().then((result) => {
        console.log(result);
        if (result && result.applePay) {
          setPaymentRequest(pr);
          setApplePaySupported(true);
        } else {
          console.error("Apple Pay is not supported on this device or not configured correctly.");
        }
        if (result) {
          setPaymentRequest(pr);
          setApplePaySupported(true);
        }
      });
    }
  }, [stripe]);

  return (
    <div className="flex flex-col justify-between">
      {/* Apple Pay Button */}
      {applePaySupported && paymentRequest ? (
        <div style={{ marginBottom: '20px' }}>
          <PaymentRequestButtonElement options={{ paymentRequest }} />
        </div>
      ) : (
        <p>Apple Pay is not available on this device.</p>
      )}
    </div>
  );
};

const ApplePayPaymentGateway = () => {
  require("dotenv").config();
  const publishableKey: string | undefined = process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY;
  const stripePromise = loadStripe(publishableKey || "");

  return (
    <Elements stripe={stripePromise}>
      <PaymentComponent />
    </Elements>
  );
};

export default ApplePayPaymentGateway;