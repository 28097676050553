import React from "react";
import LogoHeader from "../components/logoHeader";
import Footer from "../components/footer";
import PaymentMethodsForm from "../components/paymentMethodsForm";

const PaymentMethod = () => {
  return (
    <div className="center-page wrapper">
      <div className="content">
        <LogoHeader />
        <PaymentMethodsForm />
      </div>
      <Footer rightBtnText={"Pay"} leftBtnText={"Back"} />
    </div>
  );
};

export default PaymentMethod;
