import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useAppDispatch, useAppSelector, useAppStore } from "@/lib/hooks";
import { payment } from "@/app/api/endpoints/endpoints";
import {
  resetForm,
  setFormValue,
  showModal,
} from "@/lib/features/slices/paymentSlice";
import "dotenv/config";
// import { resetModal, setModalData } from "@/lib/features/slices/modalSlice";
import { paymentFormState } from "@/lib/types/types";
import { incremented } from "@/lib/features/slices/screensSlice";
import toast from "react-hot-toast";

const CardPaymentComponent = () => {
  const dispatch = useAppDispatch();
  // const [isProcessing, setIsProcessing] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState("");
  const store = useAppStore();
  const state = useAppSelector((state) => state.paymentForm);
  const { clientSecret, paymentId } = useAppSelector(
    (state) => state.paymentMethod
  );
  const { accessToken } = useAppSelector((state) => state.registrationForm);

  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    store.dispatch(
      setFormValue({ name: name as keyof paymentFormState, value })
    );
  };

  useEffect(() => {
    if (paymentStatus !== "succeeded") return;
    dispatch(resetForm());
  }, [paymentStatus, dispatch]);

  const stripe = useStripe();
  const elements = useElements();
  const handleSubmit = async () => {
    if (!stripe || !elements) return;

    const cardEl = elements.getElement(CardNumberElement);

    // setIsProcessing(true);
    try {
      const { paymentIntent, error } = await stripe.confirmCardPayment(
        clientSecret,
        {
          payment_method: {
            card: cardEl!,
          },
        }
      );

      if (error) {
        const paymentIntent = error.payment_intent;
        if (paymentIntent?.status === "succeeded") {
          toast.success("Payment Done Successfully!");
          setPaymentStatus(paymentIntent.status);
        } else {
          toast.error("Something went wrong, Please try again!");
          setPaymentStatus("Payment failed!");
        }
      } else if (paymentIntent) {
        const { status } = paymentIntent;
        // Use the status variable as needed
        if (status === "succeeded") {
          toast.success("Payment Done Successfully!");
          setPaymentStatus(paymentIntent.status);
        } else {
          toast.error("Something went wrong, Please try again!");

          setPaymentStatus("Payment failed!");
        }
      }
    } catch (error) {
      toast.error("Something went wrong, Please try again!");
      setPaymentStatus("Payment failed!");
    }
    try {
      const data = await payment(paymentId, accessToken);
      if (data?.response?.status !== 201) {
        toast.error("Something went wrong, Please try again!");
      }

      if (data?.response?.status === 201) {
        store.dispatch(showModal(false));
        store.dispatch(incremented());
      }
    } catch (error) {
      toast.error("Something went wrong, Please try again!");
    }

    // setIsProcessing(false);
  };

  return (
    <div className="flex flex-col justify-between">
      <p>Please provide your credit card details.</p>
      <form className="form-center content">
        <label htmlFor="cardNumber"> Card Number </label>
        <div className={`input-field bg-white`}>
          <CardNumberElement
            id="card-element"
            options={{
              style: {
                base: {
                  fontSize: "1.1em",
                  "::placeholder": {
                    fontSize: "1.1em",
                  },
                },
              },
              placeholder: "---- ---- ---- ----",
            }}
          />
        </div>
        <label htmlFor="cardHolderName">Card Holder Name </label>
        <input
          className={`input-field `}
          name="cardHolderName"
          type="text"
          value={state.paymentForm.cardHolderName}
          onChange={handleFieldChange}
          placeholder="Enter first and last name"
          autoComplete="off"
        />
        <div className="flex justify-between mt-4 gap-5 text-left">
          <div style={{ width: "50%" }}>
            <label htmlFor="expiryDate">Expiration Date </label>
            <div className={`input-field bg-white`}>
              <CardExpiryElement
                id="card-element"
                options={{
                  style: {
                    base: {
                      fontSize: "1.1em",
                      "::placeholder": {
                        fontSize: "1.1em",
                      },
                    },
                  },
                }}
              />
            </div>
          </div>
          <div style={{ width: "50%" }}>
            <label htmlFor="cvv">CVV Code </label>
            <div className={`input-field bg-white`}>
              <CardCvcElement
                id="card-element"
                options={{
                  placeholder: "***",
                  style: {
                    base: {
                      fontSize: "1.1em",
                      "::placeholder": {
                        fontSize: "1.1em",
                      },
                    },
                  },
                }}
              />
            </div>
          </div>
        </div>
      </form>
      {/* <p className="flex justify-center mt-5"> {paymentStatus}</p> */}
      <div className="footer my-10">
        <button
          onClick={() => handleSubmit()}
          className="footer-single-btn-dark"
        >
          <p>Pay</p>
        </button>
      </div>
    </div>
  );
};

const CardPaymentGateway = () => {
  require("dotenv").config();
  const publishableKey: string | undefined =
    process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY;
  const stripePromise = loadStripe(publishableKey || "");

  return (
    <Elements stripe={stripePromise}>
      <CardPaymentComponent />
    </Elements>
  );
};

export default CardPaymentGateway;
